import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import {Helmet} from "react-helmet"
import Navbar from "./navbar"
import Logo from "../images/logo/logo-full-colour.svg"


const Header = class extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", this.toggleBodyClass);
    this.toggleBodyClass();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleBodyClass);
  }

  toggleBodyClass = () => {
    if (window.scrollY >= 80) {
      document.getElementById("site-header").classList.add("scrolling");
      // if (document.getElementById("mkLightboxContainer")) {
      //   document.getElementById("mkLightboxContainer").classList.add("scrolling");
      // }
    } else {
      document.getElementById("site-header").classList.remove("scrolling");
    }
  };

  render() {
    return (
      <>
  <Helmet>
    <body id="top" />
  </Helmet>

  <header id="site-header">
          <div className="content-container">
          <div className="logo-wrapper">
            <a href="/">
              <img src={Logo} alt="EveryOne Logo" />
            </a>
          </div>

          <Navbar />
          
        </div>
        </header>
</>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
