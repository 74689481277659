import React from 'react'
import { Link } from 'gatsby'
import {Helmet} from 'react-helmet'

const Navbar = class extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
          active: false,
          navBarActiveClass: 'collapsed',
        }
      }

      toggleHamburger = () => {
        // toggle the active boolean in the state
        this.setState(
          {
            active: !this.state.active,
          },
          // after state has been updated,
          () => {
            // set the class in state for the navbar accordingly
            this.state.active
              ? this.setState({
                navBarActiveClass: 'expanded',
              })
              : this.setState({
                navBarActiveClass: 'collapsed',
              })
          }
        )
      }
  
      render() {
        return (
    <>
            <Helmet>
              <body data-navbar={`${this.state.navBarActiveClass}`} />
            </Helmet>
    
            <nav id="nav-wrapper" className={`navbar-icon ${this.state.navBarActiveClass}`}>
                <button
                  id="nav-toggler"
                  type="button"
                //   onClick={navToggle()}
                onClick={() => this.toggleHamburger()}
    
                  aria-label="Toggle main menu"
                >
                  <span className="menu-bar"></span>
                  <span className="menu-bar"></span>
                  <span className="menu-bar"></span>
                </button>
                <div id="nav-container">
                  <ul>
                    <li>
                      <a className="nav-link page-about-us" href="/about-us">About Us</a>
                    </li>
                    <li>
                      <a className="nav-link page-team" href="/the-team">The Team</a>
                    </li>
                    <li>
                      <a className="nav-link page-tailored-solutions" href="/tailored-solutions">Tailored Solutions</a>
                    </li>
                    <li>
                      <a className="nav-link page-why-everyone" href="/why-everyone">Why EveryOne</a>
                    </li>
                    <li>
                      <a className="nav-link page-projects" href="/projects">Projects</a>
                    </li>
                    <li>
                      <a className="nav-link page-contact" href="/contact-us">Contact</a>
                    </li>
                  </ul>
                </div>
              </nav>
    </>
        )
      }
    
}

export default Navbar




















